import { useState } from "react";
import { Modal } from "react-bootstrap";
import LazyLoad from "react-lazyload";

import IdeaCard from "../../../components/IdeaCard/IdeaCard";
import idea_data from "../../../Data/idea_list";

import "./IdeaList.css";

const IdeaList = ({ clickCall }) => {
  return (
    <div className="container idea-div">
      <div className="row container justify-content-center">
        <div className="col-sm-12 justify-content-center idea-div-text">
          <h2 className="">Nuestros paquetes que estan en descuento</h2>
          <h4 className="black-sub-head">Aquí puede ver nuestras ideas de viaje destacadas</h4>
        </div>
      </div>
      <div className="container">
        <LazyLoad height={200} once>
          <div className="row">
            <div className="col-sm-4 margin-bottom mb-2">
              <IdeaCard render_data={idea_data[0]} clickCall={clickCall} />
            </div>
            <div className="col-sm-4 margin-bottom mb-2">
              <IdeaCard render_data={idea_data[1]} clickCall={clickCall} />
            </div>
            <div className="col-sm-4 margin-bottom mb-2">
              <IdeaCard render_data={idea_data[2]} clickCall={clickCall} />
            </div>
          </div>
        </LazyLoad>
        <LazyLoad height={200} once>
          <div className="row">
            <div className="col-sm-4 margin-bottom mb-2">
              <IdeaCard render_data={idea_data[3]} clickCall={clickCall} />
            </div>
            <div className="col-sm-4 margin-bottom mb-2">
              <IdeaCard render_data={idea_data[4]} clickCall={clickCall} />
            </div>
            <div className="col-sm-4 margin-bottom mb-2">
              <IdeaCard render_data={idea_data[5]} clickCall={clickCall} />
            </div>
          </div>
        </LazyLoad>
      </div>
    </div>
  );
};

export default IdeaList;
