import React from "react";

import branchList from "../../Data/BranchData.json";
import BranchDetailsBlock from "./BranchDetailsBlock";

import "./ContactNumbersModal.css";

function ContactNumbersModal() {
  return (
    <div className="phone-grid">
      {branchList.map((object, i) => (
        <BranchDetailsBlock {...object} key={i} />
      ))}
    </div>
  );
}

export default ContactNumbersModal;
