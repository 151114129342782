import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import { tokenCheck } from "../../api/authAPI";

import "./ProtectedRoute.css";

function ProtectedRoute({ clickCall, component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("vc-is-valid-auth");

  useEffect(() => {
    const token = localStorage.getItem("vc-auth-token");
    const medium = localStorage.getItem("vc-auth-type");

    if (medium === "DB") {
      if (token) {
        tokenCheck({
          token: token,
        }).then((data) => {
          try {
            if (data["result"]) {
              if (data["result"] === "SUCCESS") {
                localStorage.setItem("vc-is-valid-auth", true);
              } else if (data["result"] === "FAILED") {
                localStorage.setItem("vc-is-valid-auth", false);
                logOutClick();
              } else {
                localStorage.setItem("vc-is-valid-auth", false);
                logOutClick();
              }
            } else {
              localStorage.setItem("vc-is-valid-auth", false);
              logOutClick();
            }
          } catch (error) {
            localStorage.setItem("vc-is-valid-auth", false);
            logOutClick();
          }
        });
      } else {
        localStorage.setItem("vc-is-valid-auth", false);
        logOutClick();
      }
    } else if (medium === "FACEBOOK") {
      localStorage.setItem("vc-is-valid-auth", true);
    } else if (medium === "GOOGLE") {
      localStorage.setItem("vc-is-valid-auth", true);
    } else {
      localStorage.setItem("vc-is-valid-auth", false);
      logOutClick();
    }
  });

  const logOutClick = () => {
    localStorage.removeItem("vc-auth-firstName");
    localStorage.removeItem("vc-auth-lastName");
    localStorage.removeItem("vc-auth-email");
    localStorage.removeItem("vc-auth-role");
    localStorage.removeItem("vc-auth-token");
    localStorage.removeItem("vc-auth-type");
    localStorage.removeItem("vc-auth-url");
    localStorage.removeItem("vc-is-valid-auth");
    localStorage.removeItem("vc-auth-first-login");

    window.location.href = "/";
  };

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <>
            <Component clickCall={clickCall} {...props} />
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default ProtectedRoute;
