import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import HeroSection from "./HeroSection/HeroSection";
import IdeaList from "./IdeaList/IdeaList";

import "./PackagePage.css";

function PackagePage({ clickCall }) {
  const [couponModalShow, setCouponModalShow] = useState(false);

  useEffect(() => {
    const isFirstLogin = localStorage.getItem("vc-auth-first-login");

    setTimeout(() => {
      if (isFirstLogin === "true") {
        setCouponModalShow(true);
        localStorage.setItem("vc-auth-first-login", false);

        let couponValue = "VC" + Math.floor(Math.random() * 100000000);
        localStorage.setItem("vc-coupon-value", couponValue);
      }
    }, 3000);
  }, []);

  return (
    <>
      <HeroSection />
      <IdeaList clickCall={clickCall} />
      <Modal
        show={couponModalShow}
        onHide={() => setCouponModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="celebrate-body">
          <img
            className="celebrate-img"
            src="/images/celebrate.jpg"
            alt="offer"
          ></img>
          <div className="congratulations-div">
            <h3>¡¡Felicidades!!</h3>
            <p>
              ¡Tienes una oferta del 45% para tu próxima reserva de vacaciones!
            </p>
            <h1>{`VC${Math.floor(Math.random() * 100000000)}`}</h1>
            <p>
              ¡Llámenos con el número de cupón anterior dentro de las próximas
              24 horas para obtener esta oferta!
            </p>
            <p
              onClick={() => clickCall()}
              className="btn-call-us-top btn-width-ch font-fm"
            >
              Llámanos Ahora
            </p>
          </div>
        </Modal.Body>
      </Modal>
      {localStorage.getItem("vc-coupon-value") && (
        <div className="promo-code-tab">
          <p>Código Promocional</p>
          <h4>{localStorage.getItem("vc-coupon-value")}</h4>
        </div>
      )}
    </>
  );
}

export default PackagePage;
