import React from "react";

import { AiFillStar } from "react-icons/ai";

import AboutCardSection from "./AboutCardSection/AboutCardSection";
import ImgDivCardTwo from "./ImgDivCardTwo/ImgDivCardTwo";

import "./homePage.css";

function HomePage({ clickLogin, clickCall }) {
  const offerPageRe = () => {
    window.location.href = "/packages";
  };

  return (
    <>
      <div className="relative">
        <div id="full_body_size" className="parallax-img-home">
          <div className="container">
            <div className="row home-padding-top-set d-flex justify-content-center">
              <div className="col-sm-10">
                <p className="header-sub-text-style-home font-size-set-42 font-fm-medium">¿Te gustaría vacacionar por un 45% menos?</p>
              </div>
            </div>
            <div className="row s-1-div">
              <div className="col-sm-4 col-md-12 col-lg-4 img-s-1">
                <img className="side-img-1" src="/images/side-images/Harmony_Seas.jpg" alt="side-1"></img>
              </div>
              <div className="col-sm-8 col-md-12 col-lg-8 right-s-1-d">
                <div className="row pt-3">
                  <div className="col-sm-12 hero-left-margin">
                    <h4 className="font-fm-medium h4-s-1">Regístrese para obtener un 45% de Descuento</h4>
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <AiFillStar color="orange" />
                    <p className="font-fm gray">más de 3000k clientes utilizaron nuestros paquetes</p>

                    <p className="font-fm gray">
                      Esta oferta imperdible es para usuarios registrados en nuestro sitio. Regístrate ahora para obtener más detalles sobre
                      los paquetes de vacaciones y más ofertas increíbles.
                    </p>
                  </div>
                </div>
                <div className="row d-flex justify-content-start mb-3">
                  <div className="col-sm-12 col-md-8 col-lg-7 col-xl-7 home-search-box-style">
                    {!localStorage.getItem("vc-is-valid-auth") && (
                      <p
                        id="no-destination-btn-home"
                        onClick={() => clickLogin()}
                        className="btn-not-destination-select btn-not-destination-select-active font-fm"
                      >
                        Registrate ahora
                      </p>
                    )}
                    {localStorage.getItem("vc-is-valid-auth") && (
                      <p
                        id="no-destination-btn-home"
                        onClick={() => offerPageRe()}
                        className="btn-not-destination-select btn-not-destination-select-active  font-fm-light"
                      >
                        Página de Oferta
                      </p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col hero-left-img-margin">
                    {/* <img src="images/side-images/logo_XC.png" alt="s"></img> */}
                    <img className="" src="images/side-images/logo_cancun_png_2.png" alt="s"></img>
                  </div>
                  <div className="col price-s-1-d">
                    <span className="font-fm old-price-s gray">¡¡Date prisa!!</span>
                    <p className="font-fm-light price-value-new font-size-set-24">Más de 20 paquetes</p>
                    <p className="font-fm gray kids-p-s">Hoteles de lujo</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-img-back">
          <div id="about-us-div" className="container about-us-details-home pb-5">
            <div className="row pt-5 d-flex justify-content-center">
              <div className="col-sm-10">
                <p className="about-us-sub-home-text font-size-set-40 font-fm-light">
                  ¡Habitaciones con 45% de Descuento para Usuarios Registrados!
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center  mb-1">
              <div className="col-sm-8">
                <p className="steps-p-sub font-size-set-14 text-align-set font-fm">
                  Sabemos que hay lugares a los que deseas ir, paisajes que deseas ver, personas que deseas visitar. Y ahora podrás hacer
                  todo eso a un precio mucho más bajo luego de registrarte. Tenemos un gran número de cruceros, hoteles y complejos
                  turísticos disponibles para ti. ¡Reserva desde ya!
                </p>
              </div>
            </div>
            <AboutCardSection />
            <ImgDivCardTwo clickLogin={clickLogin} clickCall={clickCall} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
