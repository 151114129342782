import React from "react";

import ReactCountryFlag from "react-country-flag";

import "./BranchDetailsBlock.css";

function BranchDetailsBlock({
  countryCode,
  branchName,
  telNumber,
  telRef,
  address,
}) {
  return (
    <div className="branch-details-div">
      <div className="inline-flag">
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            width: "3em",
            height: "3em",
            marginRight: "15px",
          }}
          title={countryCode}
        />
        <div className="name-and-phone">
          <p className="branch-head text-size-22">{branchName}</p>
          <a href={telRef} className="branch-tel text-size-16">
            {telNumber}
          </a>
        </div>
      </div>
    </div>
  );
}

export default BranchDetailsBlock;
