import React, { Component, useState } from "react";

// import {
//   MdOutlineNightlightRound,
//   MdFlight,
//   MdLocationOn,
// } from "react-icons/*";
import { ImLocation } from "react-icons/im";
import { FaMoon, FaTelegramPlane } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";

import "./IdeaCard.css";
import { Modal } from "react-bootstrap";

// class IdeaCard extends Component {
const IdeaCard = ({ render_data, clickCall }) => {
  const [moreDetailModalShow, setMoreDetailModalShow] = useState(false);
  return (
    <>
      <div
        className="landing-page-card"
        // href={this.props.render_data.href}
        // target="_blank"
        // rel="noreferrer"
      >
        <div className="img-card-idea">
          <img className="img-hotel-card-1" src={render_data.package_home_img} alt="side-1"></img>
          <p>{render_data?.package_name}</p>
        </div>

        <div className="card-content">
          <div>
            <h3>{render_data.sub_topic}</h3>
            <h4>
              Desde
              <span className="price-tag">
                <span>$</span>
                {render_data.price_per_person}
              </span>
              por persona
            </h4>
          </div>
          <span className="star-section">
            <AiFillStar color="orange" />
            <AiFillStar color="orange" />
            <AiFillStar color="orange" />
            <AiFillStar color="orange" />
            <AiFillStar color="orange" />
            <span className="reviews">{render_data.package_rate_number}</span>
            <span className="reviews">Reseñas</span>
          </span>

          <hr />

          <div className="flex-v">
            <span className={render_data.no_of_flights === 0 ? "hidden-span" : null}>
              <FaTelegramPlane />
              <h5>
                {render_data.no_of_flights}
                <span className="sp">Transportes</span>
              </h5>
            </span>
            <span className={render_data.no_of_locations === 0 ? "hidden-span" : null}>
              <ImLocation />
              <h5>
                {render_data.no_of_locations}
                <span className="sp">Destino</span>
              </h5>
            </span>
            <span className={render_data.no_of_nights === 0 ? "hidden-span" : null}>
              <FaMoon />
              <h5>
                {render_data.no_of_nights}
                <span className="sp">Noches</span>
              </h5>
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p onClick={() => setMoreDetailModalShow(true)} className="btn-more-card font-fm">
              Ver más
            </p>
          </div>
          <div className="col">
            <p onClick={() => clickCall()} className="btn-call-card font-fm">
              Llámanos Ahora
            </p>
          </div>
        </div>
      </div>
      <Modal
        show={moreDetailModalShow}
        onHide={() => setMoreDetailModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h5 className="font-fm h4-s-1">{render_data.package_name}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row card-popup-div">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-5 col-md-12 col-lg-5">
                  <img className="img-card-div-img" src={render_data.package_home_img} alt="side-1"></img>
                  <p onClick={() => clickCall()} className="btn-about-us-select-top font-fm">
                    Llámanos Ahora
                  </p>
                </div>
                <div className="col-sm-7 col-md-12 col-lg-7">
                  <div className="row mb-1">
                    <div className="col-sm-12">
                      {/* <AiFillStar color="#ffa500" />
                      <AiFillStar color="#ffa500" />
                      <AiFillStar color="#ffa500" />
                      <AiFillStar color="#ffa500" />
                      <AiFillStar color="#dbe181" />
                      <p className="font-fm gray">
                        {render_data.package_rate_number} clientes les gusta
                        este paquete
                      </p> */}

                      <h5 className="font-fm text-bold mt-1">INCLUYE:</h5>
                      <ul>
                        {render_data.includes?.map((item, index) => (
                          <li>
                            <p className="gray" key={index}>
                              {item.name}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 font-center">
                      <p className="img-div-p-h font-fm gray">¿No tienes Fechas de Viaje? ¡No importa!</p>
                      <p className="img-div-p-h font-fm gray">
                        Si todavía no tienes fechas de viaje, no hay problema. Llama ahora y asegura tu precio de promoción. Escoje tus
                        fechas de viaje más tarde.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-sm-12">
              <AiFillStar color="#ffa500" />
              <AiFillStar color="#ffa500" />
              <AiFillStar color="#ffa500" />
              <AiFillStar color="#ffa500" />
              <AiFillStar color="#dbe181" />
              <p className="font-fm gray">
                {render_data.package_rate_number} clientes les gusta este
                paquete
              </p>

              <h5 className="font-fm">
                <b>INCLUYE:</b>
              </h5>
              <ul>
                {render_data.includes?.map((item, index) => (
                  <li className="font-fm gray" key={index}>
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IdeaCard;
