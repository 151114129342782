import React, { Component } from "react";

import { AiFillStar } from "react-icons/ai";

import "./AboutCardSection.css";
import "./AboutNewCss.css";

class AboutCardSection extends Component {
  state = {};
  render() {
    return [
      <div>
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-center mb-5">
            <iframe
              className="about-us-video"
              src="https://www.youtube.com/embed/IM3iqx5UBFU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>,
    ];
  }
}

export default AboutCardSection;
