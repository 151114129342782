import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup, Label } from "reactstrap";

import { signInEnd } from "../../../api/authAPI";

import "./Sign-in.css";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignIn = ({ changeModalContent }) => {
  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [isUserCreateSuccess, setIsUserCreateSuccess] = useState(false);

  function successSubmission(data) {
    setIsUserCreateSuccess(true);
    changeModalContent(true);
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  return (
    <>
      {!isLoading && (
        <>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12">
                <div className="invoice-form-div">
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      password: "",
                      role: "user",
                      phoneNumber: "",
                    }}
                    validationSchema={Yup.object({
                      firstName: Yup.string()
                        .min(2, "FirstName cannot smaller than 2 characters")
                        .max(50, "FirstName cannot exceed 50 characters")
                        .required("Required"),
                      lastName: Yup.string()
                        .min(2, "LastName cannot smaller than 2 characters")
                        .max(50, "LastName cannot exceed 50 characters")
                        .required("Required"),
                      email: Yup.string()
                        .max(100, "Email cannot exceed 100 characters")
                        .email("Invalid Email")
                        .required("Required"),
                      password: Yup.string()
                        .min(2, "Password cannot smaller than 2 characters")
                        .max(60, "Password cannot exceed 60 characters")
                        .required("Required"),
                      role: Yup.string().required("Required"),
                      phoneNumber: Yup.string()
                        .matches(phoneRegExp, "Phone number is not valid")
                        .required("Required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setIsLoading(true);
                      setIsFailSubmission(false);

                      signInEnd({
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        password: values.password,
                        role: values.role,
                        phoneNumber: values.phoneNumber,
                      }).then((data) => {
                        try {
                          if (data["result"]) {
                            if (data["result"] === "SUCCESS") {
                              successSubmission(data["data"]);
                            } else if (data["result"] === "FAILED") {
                              failSubmission(
                                "¡Error!",
                                data["data"]["errorMessage"]
                              );
                            } else {
                              failSubmission(
                                "Error!",
                                "¡Por favor, inténtelo de nuevo más tarde!"
                              );
                            }
                          } else {
                            failSubmission(
                              "Error!",
                              "¡Por favor, inténtelo de nuevo más tarde!"
                            );
                          }
                        } catch (error) {
                          if (data) {
                            if (data["result"]) {
                              if (data["result"] === "FAILED") {
                                //console.log(data["data"]);
                                failSubmission(
                                  "¡Error!",
                                  data["data"]["errorMessage"]
                                );
                              } else {
                                failSubmission(
                                  "Error!",
                                  "¡Por favor, inténtelo de nuevo más tarde!"
                                );
                              }
                            } else {
                              failSubmission(
                                "Error!",
                                "¡Por favor, inténtelo de nuevo más tarde!"
                              );
                            }
                          } else {
                            failSubmission(
                              "Error!",
                              "¡Por favor, inténtelo de nuevo más tarde!"
                            );
                          }
                        }
                      });
                    }}
                  >
                    <Form>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mb-4">
                          <div className="row">
                            <div className="col-sm-12 mb-4">
                              <h5 className="head-text-sign-in">
                                Introduce tus Detalles
                              </h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="firstName"
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  disabled={isLoading}
                                />
                                <ErrorMessage name="firstName">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="lastName"
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  disabled={isLoading}
                                />
                                <ErrorMessage name="lastName">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="email"
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                  disabled={isLoading}
                                />
                                <ErrorMessage name="email">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="phoneNumber"
                                  type="number"
                                  className="form-control"
                                  placeholder="Phone Number"
                                  disabled={isLoading}
                                />
                                <ErrorMessage name="phoneNumber">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  placeholder="Password"
                                  disabled={isLoading}
                                />
                                <ErrorMessage name="password">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          {isUserCreateSuccess && (
                            <div className="row">
                              <div className="col text-center">
                                <>
                                  <Alert color="success">
                                    <p>Successfully User Create</p>
                                    <hr />
                                    <p className="mb-0">
                                      New Your Successfully Created
                                    </p>
                                  </Alert>
                                </>
                              </div>
                            </div>
                          )}
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      {/* <p>{errorMsg.head}</p>
                                      <hr /> */}
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12 text-center">
                                <button
                                  type="submit"
                                  className="submission-btn"
                                  disabled={isLoading}
                                >
                                  Enviar Detalles
                                </button>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col text-center">
                                <Link
                                  className="link-back-login"
                                  to="/"
                                  onClick={() => {
                                    changeModalContent(true);
                                  }}
                                >
                                  Atrás para iniciar sesión
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <Alert color="warning">
                <p>Cargando....</p>
                <hr />
                <p className="mb-0">¡Espere, el contenido se está cargando!</p>
              </Alert>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
