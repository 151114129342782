import React from "react";
import LazyLoad from "react-lazyload";
import { AiFillStar, AiFillTags, AiFillTrophy } from "react-icons/ai";
import { FaSuitcase } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

import "./ImgDivCardTwo.css";
import idea_list from "../../../Data/idea_list";

function ImgDivCardTwo({ clickLogin, clickCall }) {
  return (
    <>
      <LazyLoad height={200} once>
        <div className="row card-white-div mb-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    <h4 className="img2-1-div-h4 font-fm-light text-bold mt-3">Paquetes Más Populares</h4>
                    <p className="font-fm gray">Estos son algunos de nuestros paquetes más reservados recientemente.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-md-12 col-lg-4 mb-3">
                <div className="hotel-card-1">
                  <div className="img-card">
                    <img className="img-hotel-card-1" src={idea_list[0]?.image_src} alt="side-1"></img>
                    <p>{idea_list[0]?.package_name}</p>
                  </div>
                  <div className="text-h-d p-text-div">
                    <div className="h-name-d mt-2">
                      <h5 className="font-fm">{idea_list[0]?.sub_topic}</h5>{" "}
                      <div className="h-s-d">
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <span className="all-inclusive-label">Todo Incluido</span>
                      </div>
                      <p className="font-fm gray">Increíble experiencia en la playa y el hotel de 5 estrellas.</p>
                      {!localStorage.getItem("vc-is-valid-auth") && (
                        <p onClick={() => clickLogin()} className="btn-about-us-select-top btn-width-ch font-fm">
                          Obtener Precios
                        </p>
                      )}
                      {localStorage.getItem("vc-is-valid-auth") && (
                        <>
                          <span className="gray">Desde </span>
                          <h4 className="price-tag-value">${idea_list[0]?.price_per_person}</h4>
                          <span className="gray"> por persona</span>
                          <Link className="more-details-link" to="/packages">
                            Más ofertas
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-12 col-lg-4 mb-3">
                <div className="hotel-card-1">
                  <div className="img-card">
                    <img className="img-hotel-card-1" src={idea_list[1]?.image_src} alt="side-1"></img>
                    <p>{idea_list[1]?.package_name}</p>
                  </div>
                  <div className="text-h-d">
                    <div className="h-name-d mt-2">
                      <h5 className="font-fm">{idea_list[0]?.sub_topic}</h5>
                      <div className="h-s-d">
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <span className="all-inclusive-label">Todo Incluido</span>
                      </div>
                      <p className="font-fm gray">Increíble experiencia en la playa y el hotel de 5 estrellas.</p>
                      {!localStorage.getItem("vc-is-valid-auth") && (
                        <p onClick={() => clickLogin()} className="btn-about-us-select-top btn-width-ch font-fm">
                          Obtener Precios
                        </p>
                      )}
                      {localStorage.getItem("vc-is-valid-auth") && (
                        <>
                          <span className="gray">Desde </span>
                          <h4 className="price-tag-value">${idea_list[1]?.price_per_person}</h4>
                          <span className="gray"> por persona</span>
                          <Link className="more-details-link" to="/packages">
                            Más ofertas
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-12 col-lg-4 mb-3">
                <div className="hotel-card-1">
                  <div className="img-card">
                    <img className="img-hotel-card-1" src={idea_list[2]?.image_src} alt="side-1"></img>
                    <p>{idea_list[2]?.package_name}</p>
                  </div>

                  <div className="text-h-d">
                    <div className="h-name-d mt-2">
                      <h5 className="font-fm">{idea_list[2]?.sub_topic}</h5>{" "}
                      <div className="h-s-d">
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                        <AiFillStar color="orange" />
                      </div>
                      <p className="font-fm gray">Increíble experiencia en la playa y el hotel de 5 estrellas.</p>
                      {!localStorage.getItem("vc-is-valid-auth") && (
                        <p onClick={() => clickLogin()} className="btn-about-us-select-top btn-width-ch font-fm">
                          Obtener Precios
                        </p>
                      )}
                      {localStorage.getItem("vc-is-valid-auth") && (
                        <>
                          <span className="gray">Desde </span>
                          <h4 className="price-tag-value">${idea_list[2]?.price_per_person}</h4>
                          <span className="gray"> por persona</span>
                          <Link className="more-details-link" to="/packages">
                            Más ofertas
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center font-center mt-5">
              <div className="col-sm-11">
                <div className="div-s-b">
                  <h5 className="gray mt-4">¿Deseas un plan vacacional a tu medida? ¡Te lo armamos justo como lo deseas!</h5>
                  <p onClick={() => clickCall()} className="btn-call-select-top btn-width-ch font-fm">
                    Llámanos Ahora
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-blue-div mb-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-4 font-center mb-4">
                <AiFillTags size={50} color="white" />
                <h4 className="font-fm-light white font-center mt-1">Precios sin competencia</h4>
                <p className="font-fm-light white mt-4 font-center">
                  Con más de 5000 proveedores alrededor del mundo y aliados con las mejores líneas de crucero.
                </p>
              </div>
              <div className="col-sm-4 font-center mb-4">
                <AiFillTrophy size={50} color="white" />
                <h4 className="font-fm-light white font-center mt-1">Premiados por el mejor servicio</h4>
                <p className="font-fm-light white mt-4 font-center">
                  Despreocupate, contamos con agentes de viaje calificados para atenderle las 24 horas, los 7 días de la semana.
                </p>
              </div>
              <div className="col-sm-4 font-center">
                <FaSuitcase size={50} color="white" />
                <h4 className="font-fm-light white font-center mt-1">Años de experiencia</h4>
                <p className="font-fm-light white mt-4 font-center">
                  Contamos con más de 250 agentes especialmente entrenados para asistirte en tu viaje.
                </p>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>

      <LazyLoad height={200} once>
        <div className="row card-white-div mt-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-7 col-md-12 col-lg-7">
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-10 mt-3">
                    <h4 className="img2-div-h4 font-fm text-bold mt-2">DEJA TU VIAJE EN MANOS DE UN EXPERTO</h4>
                    <h5 className="img2-div-h3 font-fm">¡Somos el mayorista de turismo por excelencia!</h5>
                    <p className="font-fm gray">
                      Sabemos y entendemos tus necesidades a la hora de viajar. Nuestra empresa cuenta con más de 250 agentes, especialmente
                      entrenados para atender tus necesidades a la hora de vacacionar, responder a tus preguntas y asistirte durante tu
                      viaje.
                    </p>
                    <p className="font-fm gray">Al comprar con nosotros estás comprando con expertos en turismo que están ahí para ti.</p>
                    <p className="font-fm gray">
                      Te ofrecemos un servicio personalizado a través de nuestros agentes internacionales, para hacer que tu experiencia con
                      nosotros sea la mejor que hayas vivido.
                    </p>
                    <p className="font-fm gray">
                      Además, contamos con nuestra Licencia de Viajes y Turismo en el Estado de la Florida y una garantía de depósito de más
                      de un millón de dólares que respaldan nuestras operaciones y aseguran tus vacaciones.
                    </p>
                    <h5 className="img2-div-h3 ">¿Qué nos hace diferente al resto de las agencias de viajes en línea?</h5>
                    <p className="font-fm gray">
                      Nos especializamos en una gama de planes vacacionales donde los principales destinos son: Miami, Orlando, Las Vegas,
                      Bahamas, Cancún y Punta Cana, a los mejores precios, sin competencia. Comprobado por los millones de clientes que han
                      confiado en nosotros.
                    </p>
                    <p className="font-fm gray">
                      Al hacer tu registro en línea, asignamos un agente internacional de viajes quien te asesorará para que hagas la
                      selección y cotización de tu viaje apropiadamente.
                    </p>
                    <p className="font-fm gray"></p>
                    <p className="font-fm gray">¿Tienes dudas?</p>
                    <p className="font-fm gray">¿No sabes qué plan seleccionar?</p>
                    <p className="font-fm gray">¿Quieres información acerca de alguno de nuestros destinos?</p>
                    <p className="font-fm gray text-bold">¡Llámanos y nuestros agentes te ayudarán!</p>

                    <p className="font-fm gray text-bold">Utilizamos conexiones seguras para proteger toda tu información.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-5 col-md-12 col-lg-5">
                <div className="row">
                  <div className="col-sm-12">
                    <img className="img-card-div-img2-d1" src="images/side-images/disney_w_the_fam.jpg" alt="side-1"></img>
                    {!localStorage.getItem("vc-is-valid-auth") && (
                      <p onClick={() => clickLogin()} className="btn-about-us-select-top font-fm">
                        Regístrate Ahora
                      </p>
                    )}
                    {localStorage.getItem("vc-is-valid-auth") && (
                      <p onClick={() => clickCall()} className="btn-about-us-select-top font-fm">
                        Llámanos para recibir una Oferta
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <img className="img1-l-card-2" src="images/side-images/m.png" alt="side-1"></img>
                    <h4 className="font-fm gray font-center img1-l-card-2-t text-bold">¿QUIENES SOMOS?</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="font-fm gray mt-4">
                      Vacation Coupon es el sitio web de viajes más grande de Estados Unidos para cruceros, hoteles y complejos turísticos
                      con descuentos inigualables. Desde su fundación en el año 2006, ha logrado que más 2 millones de clientes tengan unas
                      vacaciones de ensueño por un precio más bajo de lo normal.
                    </p>
                    <p className="font-fm gray mt-4">
                      Vacations Coupon es una agencia de viajes consolidada y miembro acreditado de Airlines Reporting Corporation (ARC),
                      International Airlines Travel Agent Network (IATAN) y Cruise Line International Association (CLIA). Ha logrado el más
                      alto nivel de reconocimiento posible de todas las principales líneas de cruceros del mundo por ventas excepcionales y
                      un servicio superior.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12 mt-4">
                    <img className="img2-l-card-2" src="images/side-images/p.png" alt="side-1"></img>
                    <h4 className="font-fm gray font-center img1-l-card-2-t text-bold">SOMOS PLANIFICADORES DE VIAJE</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <p className="font-fm gray mt-4">
                      Nuestro equipo está conformado por expertos en planificaciones en viajes al mundo. Los cuales te ayudarán a encontrar
                      la información que necesitas y brindarte soporte para planificar las vacaciones de tu familia. Ya sea que estés
                      planificando un escape de fin de semana, un crucero relajante o días y noches de diversión en los parques temáticos de
                      Disney o Universal en Orlando, relájate y deja que uno de nuestros expertos planifique todo por ti.
                    </p>
                    <p className="font-fm gray mt-4">
                      Con más de 15 años de experiencia, hemos vacacionado a más de 10 Mil familias en toda Latinoamérica, brindando
                      vacaciones inolvidables con las mejores ofertas.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center font-center">
              <div className="col-sm-8 mt-5">
                <h4 className="font-fm gray text-bold mt-1">Quieres que te llamemos?</h4>
                <p className="font-fm gray">
                  programa una llamada con uno de nuestros agentes, y te llamamos el día y en el horario que nos indiques
                </p>
                <p onClick={() => clickCall()} className="btn-call-select-top btn-width-ch font-fm">
                  Llámanos Ahora
                </p>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} once>
        <div className="row card-white-div mt-5">
          <div className="col-sm-12 pt-2 pb-2">
            <div className="row d-flex justify-content-center font-center">
              <div className="col-sm-10 mt-5">
                <h4 className="font-fm gray text-bold mt-1">PREGUNTAS FRECUENTES</h4>
                <p className="font-fm gray">
                  Para resolver tus dudas solo debes cliquear en la pregunta y una ventana emergente te indicará la respuesta.
                </p>
              </div>
            </div>
            <div className="row ml-1 mr-1 accordion-faq-div">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6">
                    <Accordion defaultActiveKey="10">
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>
                          <h5 className="home-faq-text">¿Incluye tickets aereos?</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Tickets aéreos no están Incluidos. Podemos cotizar sus tickets aéreos si tiene fecha exacta de viaje. Los
                                tickets aéreos están sujetos a cambios por la aerolínea de escogencia y son un servicio independiente a
                                nuestros planes vacacionales
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>
                          <h5 className="home-faq-text">¿Cómo puedo pagar el plan vacacional?</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Puedes realizar tu pago con nuestros agentes en línea, con tarjetas tarjeta VISA MASTERCARD, AMERICAN
                                EXPRESS o DINERS. Si deseas realizar un pago tu mismo(a), ellos te enviarán un link a tu email o whatsapp
                                donde podrás completar la información de pago con tu tarjeta crediticia. De acuerdo a las opciones de pago
                                elegidas por el comercio, podrás pagar a través de Ebanx.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="12">
                        <Accordion.Header>
                          <h5 className="home-faq-text">¿Puedo pagar mi plan en cuotas?</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Puedes pagar hasta 12 cuotas sin intereses si estas en los países Argentina, Mexico, Colombia o Peru. Otros
                                países puede variar dependiendo de su entidad crediticia.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="13">
                        <Accordion.Header>
                          <h5 className="home-faq-text">¿Necesito VISA para viajar?</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Solo Ecuador necesita visa para entrar a Mexico. El resto de paises estan exceptos de presentar una Visa en
                                Mexico y Punta Cana. Si su destino es Estados Unidos, Usted necesitara una Visa de turista para ingresar a
                                este. Consulte con la embajada más cercana en su pais.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="col-sm-6">
                    <Accordion defaultActiveKey="10">
                      <Accordion.Item eventKey="10">
                        <Accordion.Header>
                          <h5 className="home-faq-text">¿Cuándo debo realizar el pago?</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                El paquete se debe pagar de inmediato para separar el cupo, todos nuestros planes tienen precios
                                promocionales y debido a los cupos limitados y los descuentos ofrecidos, se debe que pagar lo más adelantado
                                posible para poder ser acreedor a las tarifas en oferta.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="11">
                        <Accordion.Header>
                          <h5 className="home-faq-text">¿Que documentos/garantias recibo después de realizar el pago?</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Recibirás un un recibo de procesamiento y un contrato que deberás firmar y retornarnos con los documentos de
                                identificación indicados en el mismo. También recibirás un itinerario al momento de indicarnos las fechas en
                                las cuales tiene pensado viajar.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="12">
                        <Accordion.Header>
                          <h5 className="home-faq-text">
                            ¿Puedo anular o modificar una reserva del viaje? ¿Qué gastos puede generar una anulación o modificación del
                            viaje?
                          </h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-sm-12">
                              <p className="home-faq-sub">
                                Sí, sí puedes. Pero ten en cuenta que dicha anulación puede conllevar unos gastos de cancelación. Al
                                realizar la reserva, se informa de la política de cancelación, o bien ésta figura en las condiciones
                                generales.Estos gastos de cancelación son variables según el destino, el proveedor, la tarifa reservada y
                                las fechas de antelación a la anulación. Para anular o modificar una reserva, a través del apartado CONTACTO
                                envía un email indicando el localizador de la reserva y la modificación que deseas realizar.También se puede
                                enviar un mensaje a través del apartado MIS RESERVAS, ver detalle de la reserva en cuestión y opción enviar
                                un e-mail al booking.
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
}

export default ImgDivCardTwo;
