import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, FormGroup } from "reactstrap";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Link } from "react-router-dom";
import { AiFillFacebook } from "react-icons/ai";

import { loginEnd } from "../../../api/authAPI";
import { getIP, getLocation } from "../../../api/getLocationAPI";
import { signUpLead } from "../../../api/leadAPI";

import "./Login.css";

const Login = ({ changeModalContent }) => {
  let params = new URL(document.location).searchParams;

  const [isFailSubmission, setIsFailSubmission] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ head: "", sub: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState(false);
  const [ip, setIp] = useState(null);
  const [location, setLocation] = useState(null);
  const [isFailLocation, setIsFailLocation] = useState(false);

  useEffect(() => {
    if (ip === null) {
      getIP().then((data) => {
        try {
          if (data.ip) {
            setIp(data.ip);

            getLocation({ ip: data.ip }).then((locationData) => {
              try {
                if (locationData?.City) {
                  setLocation(locationData);
                  setIsFailLocation(true);
                }
              } catch (error) {}
            });
          }
        } catch (error) {}
      });
    }
  });

  function localUserData(firstName, lastName, email, token, role, url, type, phoneNumber) {
    localStorage.setItem("vc-auth-firstName", firstName);
    localStorage.setItem("vc-auth-lastName", lastName);
    localStorage.setItem("vc-auth-email", email);
    localStorage.setItem("vc-auth-token", token);
    localStorage.setItem("vc-auth-role", role);
    localStorage.setItem("vc-auth-url", url);
    localStorage.setItem("vc-auth-type", type);
    localStorage.setItem("vc-is-valid-auth", true);
    localStorage.setItem("vc-auth-first-login", true);

    if (isFailLocation) {
      signUpLead({
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile: phoneNumber,
        cityCountry: `${location.City}, ${location.State}, ${location.CountryName}`,
        ip: ip,
        countryCode: location.CountryCode,
        sourceTitle: "Vacation Coupon Promo",
        sourceId: 35,
        sourceUrl: "https://promo.vacationcoupon.com/",
        medium: type,
        language: 1438,
        utmSource: params.get("utm_source"),
        utmContent: params.get("utm_content"),
      }).then((data) => {
        try {
          if (data["result"]) {
            if (data["result"] === "SUCCESS") {
            } else if (data["result"] === "FAILED") {
            } else {
            }
          } else {
          }
          window.location.href = "/packages";
        } catch (error) {
          window.location.href = "/packages";
        }
      });
    } else {
      signUpLead({
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile: phoneNumber,
        cityCountry: "",
        ip: ip,
        countryCode: "",
        sourceTitle: "Vacation Coupon Promo",
        sourceId: 35,
        sourceUrl: "https://promo.vacationcoupon.com/",
        medium: type,
        language: 1438,
        utmSource: params.get("utm_source"),
        utmContent: params.get("utm_content"),
      }).then((data) => {
        try {
          if (data["result"]) {
            if (data["result"] === "SUCCESS") {
            } else if (data["result"] === "FAILED") {
            } else {
            }
          } else {
          }
          window.location.href = "/packages";
        } catch (error) {
          window.location.href = "/packages";
        }
      });
    }
  }

  function successSubmission(data) {
    localUserData(data.firstName, data.lastName, data.email, data.token, data.role, null, "DB", data.phoneNumber);
  }

  function failSubmission(head, sub) {
    setIsFailSubmission(true);
    setIsLoading(false);
    setErrorMsg({
      head: head,
      sub: sub,
    });
  }

  const responseFacebook = (response) => {
    //console.log(response);
    if (response.accessToken) {
      localUserData(response.name, "", response.email, "", "user", response.picture.data.url, "FACEBOOK", "");

      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  const responseSuccessGoogle = (response) => {
    //console.log(response);
    localUserData(response.profileObj.name, "", response.profileObj.email, "", "user", response.profileObj.imageUrl, "GOOGLE", "");
    setLogin(true);
  };

  const responseFailGoogle = (response) => {
    setLogin(false);
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12">
                <div className="invoice-form-div">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().max(100, "Email cannot exceed 100 characters").email("Invalid Email").required("Required"),
                      password: Yup.string()
                        .min(2, "Password cannot smaller than 2 characters")
                        .max(60, "Password cannot exceed 60 characters")
                        .required("Required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      setIsLoading(true);
                      setIsFailSubmission(false);

                      loginEnd({
                        email: values.email,
                        password: values.password,
                      }).then((data) => {
                        try {
                          if (data["result"]) {
                            if (data["result"] === "SUCCESS") {
                              successSubmission(data["data"]);
                            } else if (data["result"] === "FAILED") {
                              failSubmission("¡Error!", data["data"]["errorMessage"]);
                            } else {
                              failSubmission("Error!", "¡Por favor, inténtelo de nuevo más tarde!");
                            }
                          } else {
                            failSubmission("Error!", "¡Por favor, inténtelo de nuevo más tarde!");
                          }
                        } catch (error) {
                          if (data) {
                            if (data["result"]) {
                              if (data["result"] === "FAILED") {
                                //console.log(data["data"]);
                                failSubmission("¡Error!", data["data"]["errorMessage"]);
                              } else {
                                failSubmission("Error!", "¡Por favor, inténtelo de nuevo más tarde!");
                              }
                            } else {
                              failSubmission("Error!", "¡Por favor, inténtelo de nuevo más tarde!");
                            }
                          } else {
                            failSubmission("Error!", "¡Por favor, inténtelo de nuevo más tarde!");
                          }
                        }
                      });
                    }}
                  >
                    <Form>
                      <div className="row d-flex justify-content-center">
                        <div className="col-sm-8 mb-4">
                          <div className="row">
                            <div className="col-sm-12 mb-4">
                              <h5 className="head-text-login">Vamos a iniciar sesión</h5>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field name="email" type="text" className="form-control" placeholder="Email" disabled={isLoading} />
                                <ErrorMessage name="email">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 mb-3">
                              <FormGroup className="input-payment-box">
                                <Field
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  placeholder="Password"
                                  disabled={isLoading}
                                />
                                <ErrorMessage name="password">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                              </FormGroup>
                            </div>
                          </div>
                          {isFailSubmission && (
                            <div className="container">
                              <div className="row">
                                <div className="col text-center">
                                  <>
                                    <Alert color="danger">
                                      {/* <p>{errorMsg.head}</p>
                                      <hr /> */}
                                      <p className="mb-0">{errorMsg.sub}</p>
                                    </Alert>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12 text-center">
                                <button type="submit" className="submission-btn" disabled={isLoading}>
                                  Acceso
                                </button>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col text-center">
                                <Link
                                  className="link-back-login"
                                  to="/"
                                  onClick={() => {
                                    changeModalContent(false);
                                  }}
                                >
                                  Crea una cuenta
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
            <hr />
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 d-flex justify-content-center">
                <FacebookLogin
                  appId="417448906704094"
                  autoLoad={false}
                  textButton=" Iniciar sesión con Facebook"
                  language="es_US"
                  fields="name,email,picture"
                  callback={responseFacebook}
                  cssClass="fb"
                  icon={<AiFillFacebook size={25} />}
                />
              </div>
            </div>
            <div className="row d-flex justify-content-center mb-5">
              <div className="col-sm-12 d-flex justify-content-center">
                <GoogleLogin
                  clientId="128467695873-qpfbdheosn0efbi6v52guocbs01o6oa8.apps.googleusercontent.com"
                  buttonText="Iniciar sesión con Google"
                  onSuccess={responseSuccessGoogle}
                  onFailure={responseFailGoogle}
                  className={"google"}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <Alert color="warning">
                <p>Cargando....</p>
                <hr />
                <p className="mb-0">¡Espere, el contenido se está cargando!</p>
              </Alert>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
