import axios from "axios";

export const getIP = async () => {
  try {
    let formData = new FormData();

    const { data } = await axios({
      method: "get",
      url: "https://api.ipify.org?format=json",
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getLocation = async ({ ip }) => {
  try {
    let formData = new FormData();

    const { data } = await axios({
      method: "get",
      url: `https://api.snoopi.io/${ip}?apikey=${process.env.REACT_APP_SNOOPI_KEY}`,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
