import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import LazyLoad from "react-lazyload";

import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Page404 from "./RootPage/page404/Page404";

import Login from "./RootPage/auth/login/Login";
import HomePage from "./RootPage/HomePage/HomePage";
import PartnersSection from "./components/partners-section/PartnersSection";
import Footer from "./components/Footer/Footer";
import { Container, Modal, Navbar, NavDropdown } from "react-bootstrap";

import "./App.css";
import ContactNumbersModal from "./components/ContactNumbersModal/ContactNumbersModal";
import PackagePage from "./RootPage/PackagePage/PackagePage";
import SignIn from "./RootPage/auth/signIn/SignIn";

function App() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [phoneModalShow, setPhoneModalShow] = useState(false);
  const [loginShow, setLoginShow] = useState(true);

  const changeModalContent = (value) => {
    setLoginShow(value);
  };

  const clickLogin = () => {
    setLoginModalShow(true);
  };

  const clickCall = () => {
    setPhoneModalShow(true);
  };

  const logOutClick = () => {
    localStorage.removeItem("vc-auth-firstName");
    localStorage.removeItem("vc-auth-lastName");
    localStorage.removeItem("vc-auth-email");
    localStorage.removeItem("vc-auth-role");
    localStorage.removeItem("vc-auth-token");
    localStorage.removeItem("vc-auth-type");
    localStorage.removeItem("vc-auth-url");
    localStorage.removeItem("vc-is-valid-auth");
    localStorage.removeItem("vc-auth-first-login");
    localStorage.removeItem("vc-coupon-value");

    window.location.href = "/";
  };

  return (
    <>
      <ScrollToTop>
        <Suspense fallback={<LoadingScreen />}>
          <Navbar variant="light">
            <Container>
              <Navbar.Brand href="/">
                <img src="/images/logo.png" alt="logo" className="nav-bar-logo"></img>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                {/* <button
                  className="btn btn-success login-btn margin-right-5"
                  onClick={() => setPhoneModalShow(true)}
                >
                  Llama
                </button> */}
                {!localStorage.getItem("vc-is-valid-auth") && (
                  <button className="btn btn-info login-btn" onClick={() => setLoginModalShow(true)}>
                    Acceso
                  </button>
                )}
                {localStorage.getItem("vc-auth-type") === "DB" && (
                  <>
                    <img className="profile-img" src="/images/empty_profile.png" alt="" />
                    <NavDropdown title={`${localStorage.getItem("vc-auth-firstName")}`} className="logout-head">
                      {/* <NavDropdown.Item onClick={() => setOpen(!open)}>
                        Edit Profile
                      </NavDropdown.Item> */}
                      <NavDropdown.Item onClick={logOutClick}>Cerrar Sesión</NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {localStorage.getItem("vc-auth-type") === "FACEBOOK" && (
                  <>
                    <img className="profile-img" src={localStorage.getItem("vc-auth-url")} alt="" />
                    <NavDropdown title={`${localStorage.getItem("vc-auth-firstName")}`} className="logout-head">
                      <NavDropdown.Item onClick={logOutClick}>Cerrar Sesión</NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {localStorage.getItem("vc-auth-type") === "GOOGLE" && (
                  <>
                    <img className="profile-img" src={localStorage.getItem("vc-auth-url")} alt="" />
                    <NavDropdown title={`${localStorage.getItem("vc-auth-firstName")}`} className="logout-head">
                      <NavDropdown.Item onClick={logOutClick}>Cerrar Sesión</NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route path="/" exact render={(props) => <HomePage clickLogin={clickLogin} clickCall={clickCall} />} />
            <ProtectedRoute path="/packages" exact component={PackagePage} clickCall={clickCall} />
            <Route path="*" exact={true} component={Page404} />
          </Switch>
        </Suspense>
        <LazyLoad height={200} once>
          <PartnersSection />
        </LazyLoad>
        <LazyLoad height={200} once>
          <Footer />
        </LazyLoad>
      </ScrollToTop>{" "}
      <Modal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        {loginShow && (
          <>
            <Modal.Body>{!localStorage.getItem("vc-is-valid-auth") && <Login changeModalContent={changeModalContent} />}</Modal.Body>
          </>
        )}
        {!loginShow && (
          <>
            <Modal.Body>{!localStorage.getItem("vc-is-valid-auth") && <SignIn changeModalContent={changeModalContent} />}</Modal.Body>
          </>
        )}
      </Modal>
      <Modal
        show={phoneModalShow}
        onHide={() => setPhoneModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ContactNumbersModal />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default App;
