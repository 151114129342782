import React from "react";

import "./HeroSection.css";

function HeroSection() {
  return (
    <>
      <div className="slide-container">
        <div className="container">
          <div className="row hero-content">
            <div className="col-sm-12 font-center">
              <div className="col-sm-12 margin-top-hero font-center">
                <h1 className="font-fm-medium header-top-font hero-welcome-font">
                  Bienvenido(a) {localStorage.getItem("vc-auth-firstName")}
                </h1>
                <h1 className="font-fm-medium header-top-font hero-welcome-font hero-s-text">
                  Ahora puede reservar nuestros planes y obtener una oferta de
                  hasta un 45%
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
