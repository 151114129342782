import axios from "axios";

export const signUpLead = async ({
  firstName,
  lastName,
  email,
  mobile,
  cityCountry,
  ip,
  countryCode,
  sourceTitle,
  sourceId,
  sourceUrl,
  medium,
  language,
  utmSource,
  utmContent,
}) => {
  try {
    let formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("cityCountry", cityCountry);
    formData.append("ip", ip);
    formData.append("countryCode", countryCode);
    formData.append("language", language);
    formData.append("sourceTitle", sourceTitle);
    formData.append("sourceId", sourceId);
    formData.append("sourceUrl", sourceUrl);
    formData.append("medium", medium);
    formData.append("utmSource", utmSource);
    formData.append("utmContent", utmContent);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/api/bitrix_crm/create_sign_up.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
