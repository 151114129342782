const idea_list = [
  {
    package_name: "Blue Bay Emerald Hotel",
    sub_topic: "6 Días | 5 Noches de alojamiento",
    price_per_person: "498",
    no_of_locations: 3,
    no_of_nights: 5,
    no_of_flights: 0,
    image_src: "https://res.cloudinary.com/template-generator/image/upload/v1655314456/other/rosewood_hero_gfhhur.jpg",
    translate_prefix: "package_cards",
    package_home_img: "https://res.cloudinary.com/template-generator/image/upload/v1655314456/other/rosewood_hero_gfhhur.jpg",
    package_rate_value: 4.5,
    package_rate_number: "1,554",
    includes: [
      {
        name: "6 Días | 5 Noches de alojamiento",
      },
      {
        name: "Las cristalinas y turquesas aguas del Mar Caribe bañan la costa de Playa del Carmen, un maravilloso y natural lugar donde se ubica este fantástico hotel en México",
      },
    ],
  },
  {
    package_name: "Sandos Playacar Hotel",
    sub_topic: "6 Días | 5 Noches de alojamiento",
    price_per_person: "749",
    no_of_locations: 1,
    no_of_nights: 5,
    no_of_flights: 0,
    image_src: "https://res.cloudinary.com/template-generator/image/upload/v1655314456/other/Sandos_Playacar_2__tqwpgt.jpg",
    href: "https://ofertas.vacationcoupon.com/orlando-oferta",
    translate_prefix: "package_cards",
    package_home_img: "https://res.cloudinary.com/template-generator/image/upload/v1655314456/other/Sandos_Playacar_2__tqwpgt.jpg",
    package_rate_value: 4.8,
    package_rate_number: "2,554",
    includes: [
      {
        name: "6 Días | 5 Noches de alojamiento",
      },
      {
        name: "Sandos Playacar es un resort todo incluido en Playa del Carmen, México. Nuestro resort de playa se ubica en una de las mejores playas de arena blanca de la Riviera Maya",
      },
    ],
  },
  {
    package_name: "Sandos Caracol Hotel",
    sub_topic: "6 Días | 5 Noches de alojamiento",
    price_per_person: "776",
    no_of_locations: 1,
    no_of_nights: 5,
    no_of_flights: 0,
    image_src: "https://res.cloudinary.com/template-generator/image/upload/v1655314456/other/89237165_havy0i.jpg",
    href: "https://ofertas.vacationcoupon.com/punta-cana-inicio",
    translate_prefix: "package_cards",
    package_home_img: "https://res.cloudinary.com/template-generator/image/upload/v1655314456/other/89237165_havy0i.jpg",
    package_rate_value: 4.8,
    package_rate_number: "2,114",
    includes: [
      {
        name: "6 días / 5 noches de alojamiento en uno de nuestros Hotel/Resorts en Cancun por persona",
      },
      {
        name: "Sandos Caracol Eco Resort es un hotel todo incluido en Playa del Carmen. Este resort familiar tiene todo para las vacaciones de playa perfectas en México.",
      },
    ],
  },
  {
    package_name: "Ocean Resort Cancun",
    sub_topic: "6 Días | 5 Noches de alojamiento",
    price_per_person: "249",
    no_of_locations: 3,
    no_of_nights: 5,
    no_of_flights: 0,
    image_src: "https://res.cloudinary.com/template-generator/image/upload/v1655314617/other/Ocean_view_cancun_arenas_xhn35g.jpg",
    translate_prefix: "package_cards",
    package_home_img: "https://res.cloudinary.com/template-generator/image/upload/v1655314617/other/Ocean_view_cancun_arenas_xhn35g.jpg",
    package_rate_value: 4.8,
    package_rate_number: "1,674",
    includes: [
      {
        name: "6 Días | 5 Noches de alojamiento",
      },
      {
        name: "Las cristalinas y turquesas aguas del Mar Caribe bañan la costa de Playa del Carmen, un maravilloso y natural lugar donde se ubica este fantástico hotel en México",
      },
    ],
  },
  {
    package_name: "Imperial Resort Cancun",
    sub_topic: "6 Días | 5 Noches de alojamiento",
    price_per_person: "298",
    no_of_locations: 3,
    no_of_nights: 5,
    no_of_flights: 0,
    image_src: "https://res.cloudinary.com/template-generator/image/upload/v1655314617/other/IMPERIAL_RESORT_CANCUN_gaxt2j.jpg",
    translate_prefix: "package_cards",
    package_home_img: "https://res.cloudinary.com/template-generator/image/upload/v1655314617/other/IMPERIAL_RESORT_CANCUN_gaxt2j.jpg",
    package_rate_value: 4.7,
    package_rate_number: "1,654",
    includes: [
      {
        name: "6 Días | 5 Noches de alojamiento",
      },
      {
        name: "Las cristalinas y turquesas aguas del Mar Caribe bañan la costa de Playa del Carmen, un maravilloso y natural lugar donde se ubica este fantástico hotel en México",
      },
    ],
  },
  {
    package_name: "All Ritmo Resort Cancun",
    sub_topic: "6 Días | 5 Noches de alojamiento",
    price_per_person: "299",
    no_of_locations: 3,
    no_of_nights: 5,
    no_of_flights: 0,
    image_src: "https://res.cloudinary.com/template-generator/image/upload/v1655314617/other/image004_55_660x440_dqdxjr.jpg",
    translate_prefix: "package_cards",
    package_home_img: "https://res.cloudinary.com/template-generator/image/upload/v1655314617/other/image004_55_660x440_dqdxjr.jpg",
    package_rate_value: 4.4,
    package_rate_number: "1,444",
    includes: [
      {
        name: "6 Días | 5 Noches de alojamiento",
      },
      {
        name: "Las cristalinas y turquesas aguas del Mar Caribe bañan la costa de Playa del Carmen, un maravilloso y natural lugar donde se ubica este fantástico hotel en México",
      },
    ],
  },
];

export default idea_list;
